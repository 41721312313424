import { Suspense } from "react";

const PageLoader: any = (Component: any) => (props: JSX.IntrinsicAttributes) =>
  (
    <Suspense fallback={<PageLoading />}>
      <Component {...props} />
    </Suspense>
  );

export default PageLoader;

function PageLoading() {
  return (
    <div className="w-full h-screen px-10 py-10 flex justify-center items-center flex-col gap-y-4">
      <p className="uppercase font-medium text-4xl tracking-widest">
        Loading...
      </p>
    </div>
  );
}
