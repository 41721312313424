export const RouteName = {
  Home: "/",
  About: "/about",
  Pricing: "/pricing",
  Blog: "/blogs",
  BlogDetail: "/blogs/:id",
  Contact: "/contact",
  Status404: "*",
};

export const routeNameExist = (path: string) => {
  const routes = [
    RouteName.Home,
    RouteName.About,
    RouteName.Pricing,
    RouteName.Blog,
    RouteName.Contact,
  ];
  return routes.includes(path) || path.startsWith(`${RouteName.Blog}/`);
};
