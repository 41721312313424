export default function InstagramIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0223 4.84375C7.16518 4.84375 4.88839 7.16518 4.88839 9.97768C4.88839 12.8348 7.16518 15.1116 10.0223 15.1116C12.8348 15.1116 15.1562 12.8348 15.1562 9.97768C15.1562 7.16518 12.8348 4.84375 10.0223 4.84375ZM10.0223 13.3259C8.19196 13.3259 6.67411 11.8527 6.67411 9.97768C6.67411 8.14732 8.14732 6.67411 10.0223 6.67411C11.8527 6.67411 13.3259 8.14732 13.3259 9.97768C13.3259 11.8527 11.8527 13.3259 10.0223 13.3259ZM16.5402 4.66518C16.5402 3.99554 16.0045 3.45982 15.3348 3.45982C14.6652 3.45982 14.1295 3.99554 14.1295 4.66518C14.1295 5.33482 14.6652 5.87054 15.3348 5.87054C16.0045 5.87054 16.5402 5.33482 16.5402 4.66518ZM19.933 5.87054C19.8438 4.26339 19.4866 2.83482 18.3259 1.67411C17.1652 0.513393 15.7366 0.15625 14.1295 0.0669643C12.4777 -0.0223214 7.52232 -0.0223214 5.87054 0.0669643C4.26339 0.15625 2.87946 0.513393 1.67411 1.67411C0.513393 2.83482 0.15625 4.26339 0.0669643 5.87054C-0.0223214 7.52232 -0.0223214 12.4777 0.0669643 14.1295C0.15625 15.7366 0.513393 17.1205 1.67411 18.3259C2.87946 19.4866 4.26339 19.8438 5.87054 19.933C7.52232 20.0223 12.4777 20.0223 14.1295 19.933C15.7366 19.8438 17.1652 19.4866 18.3259 18.3259C19.4866 17.1205 19.8438 15.7366 19.933 14.1295C20.0223 12.4777 20.0223 7.52232 19.933 5.87054ZM17.7902 15.8705C17.4777 16.7634 16.7634 17.433 15.9152 17.7902C14.5759 18.3259 11.4509 18.192 10.0223 18.192C8.54911 18.192 5.42411 18.3259 4.12946 17.7902C3.23661 17.433 2.56696 16.7634 2.20982 15.8705C1.67411 14.5759 1.80804 11.4509 1.80804 9.97768C1.80804 8.54911 1.67411 5.42411 2.20982 4.08482C2.56696 3.23661 3.23661 2.56696 4.12946 2.20982C5.42411 1.67411 8.54911 1.80804 10.0223 1.80804C11.4509 1.80804 14.5759 1.67411 15.9152 2.20982C16.7634 2.52232 17.433 3.23661 17.7902 4.08482C18.3259 5.42411 18.192 8.54911 18.192 9.97768C18.192 11.4509 18.3259 14.5759 17.7902 15.8705Z"
        fill="white"
      />
    </svg>
  );
}
