import logo from "assets/images/logo.jpg";
import { RouteName } from "constants/route";
import { Link, NavLink } from "react-router-dom";
import MenuIcon from "./icons/MenuIcon";
import MainMenu from "./MainMenu";
import { useState } from "react";
import Home from "pages/Home";

export default function Header() {
  const [showMainMenu, setShowMainMenu] = useState<boolean>(false);

  const handleOpenMainMenu = () => setShowMainMenu(true);
  const handleCloseMainMenu = () => setShowMainMenu(false);
  return (
    <>
      <header className="w-full sm:h-132 h-73 flex justify-center items-end sm:items-center fixed bg-dark z-40">
        <nav className="w-full max-w-1246 mx-auto flex items-center justify-between h-66 px-6">
          <div className="flex gap-x-8 items-center">
            <Link to={RouteName.Home} className="flex items-center gap-x-4">
              <img src={logo} alt="logo" height={20} className="h-6" />
              <p className="uppercase text-base font-bold tracking-widest">
                Melabooks
              </p>
            </Link>
            <div className="h-66 border-r border-dark-blue hidden sm:block" />
            <div className="gap-x-27 items-center hidden sm:flex">
              <NavItem to={RouteName.Home} text="Home" />
              <NavItem to={RouteName.About} text="About" />
              <NavItem to={RouteName.Pricing} text="Pricing" />
              <NavItem to={RouteName.Blog} text="Blog" />
              <NavItem to={RouteName.Contact} text="Contact us" />
            </div>
          </div>
          <Link
            to={
              "https://play.google.com/store/apps/details?id=com.melabooks.Mela&pcampaignid=web_share"
            }
            target="_blank"
            className="uppercase text-base font-bold tracking-widest rounded-80 px-8 py-4 bg-blue hidden sm:block"
          >
            Download App
          </Link>
          <button
            onClick={handleOpenMainMenu}
            className="outline-none rounded-full h-45 w-45 bg-blue flex sm:hidden justify-center items-center"
          >
            <MenuIcon />
          </button>
        </nav>
      </header>
      <MainMenu show={showMainMenu} handleClose={handleCloseMainMenu} />
    </>
  );
}

function NavItem({ to, text }: { to: string; text: string }) {
  return (
    <NavLink
      to={to}
      className={(_) => `uppercase font-medium text-sm tracking-widest`}
    >
      {text}
    </NavLink>
  );
}
