export default function FacebookIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.8571 0H2.14286C0.9375 0 0 0.982143 0 2.14286V17.8571C0 19.0625 0.9375 20 2.14286 20H8.25893V13.2143H5.44643V10H8.25893V7.58929C8.25893 4.82143 9.91071 3.25893 12.4107 3.25893C13.6607 3.25893 14.9107 3.48214 14.9107 3.48214V6.20536H13.5268C12.1429 6.20536 11.6964 7.05357 11.6964 7.94643V10H14.7768L14.2857 13.2143H11.6964V20H17.8571C19.0179 20 20 19.0625 20 17.8571V2.14286C20 0.982143 19.0179 0 17.8571 0Z"
        fill="white"
      />
    </svg>
  );
}
