export default function CloseIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.41406"
        width="25"
        height="2"
        transform="rotate(45 1.41406 0)"
        fill="white"
      />
      <rect
        y="18"
        width="25"
        height="2"
        transform="rotate(-45 0 18)"
        fill="white"
      />
    </svg>
  );
}
