import { RouteName } from "constants/route";
import { NavLink, Link } from "react-router-dom";
import CloseIcon from "components/icons/CloseIcon";
import logo from "assets/images/logo.jpg";

interface IProp {
  show: boolean;
  handleClose: () => void;
}

export default function MainMenu({ show, handleClose }: IProp) {
  return (
    <div
      className={`${
        show ? "right-0" : "-right-1246"
      } fixed sm:hidden transition-all duration-500 top-0 w-full z-50`}
    >
      <div className="w-full min-h-screen flex flex-col bg-dark-blue relative">
        <div className="w-full flex items-center justify-between h-66 px-6">
          <div className="flex gap-x-8 items-center">
            <Link
              onClick={handleClose}
              to={RouteName.Home}
              className="flex items-center gap-x-4"
            >
              <img src={logo} alt="logo" height={20} className="h-6" />
              <p className="uppercase text-base font-bold tracking-widest">
                Melabooks
              </p>
            </Link>
          </div>
          <button
            onClick={handleClose}
            className="outline-none rounded-full h-45 w-45 bg-blue flex sm:hidden justify-center items-center"
          >
            <CloseIcon />
          </button>
        </div>
        <div className="w-full flex flex-col gap-y-10 text-32 font-bold uppercase items-center grow pt-10">
          <NavItem to={RouteName.Home} text="Home" onClick={handleClose} />
          <NavItem to={RouteName.About} text="About" onClick={handleClose} />
          <NavItem
            to={RouteName.Pricing}
            text="Pricing"
            onClick={handleClose}
          />
          <NavItem to={RouteName.Blog} text="Blog" onClick={handleClose} />
          <NavItem
            to={RouteName.Contact}
            text="contact us"
            onClick={handleClose}
          />
          <Link
            to={"#"}
            className="text-base rounded-80 px-16 py-23 bg-blue text-center w-fit"
          >
            {" "}
            Download App
          </Link>
        </div>
      </div>
    </div>
  );
}

function NavItem({
  to,
  text,
  onClick,
}: {
  to: string;
  text: string;
  onClick: () => void;
}) {
  return (
    <NavLink
      className={({ isActive }) => (isActive ? "underline" : "")}
      to={to}
      onClick={onClick}
    >
      {text}
    </NavLink>
  );
}
