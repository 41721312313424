export default function MenuIcon() {
  return (
    <svg
      width="25"
      height="20"
      viewBox="0 0 25 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="25" height="2" fill="white" />
      <rect y="9" width="25" height="2" fill="white" />
      <rect y="18" width="25" height="2" fill="white" />
    </svg>
  );
}
