import { ReactNode, Suspense } from "react";
import logo from "assets/images/logo.jpg";

export default function SuspenseLoader({ children }: { children: ReactNode }) {
  return <Suspense fallback={<PageLoading />}>{children}</Suspense>;
}

function PageLoading() {
  return (
    <div className="w-full h-screen px-10 py-10 flex justify-center items-center flex-col gap-y-4">
      <img src={logo} alt="logo" width={250} height={250} />
      <p className="uppercase font-medium text-4xl tracking-widest">
        melabooks
      </p>
    </div>
  );
}
