import { Link, useLocation } from "react-router-dom";
import logo from "assets/images/logo.jpg";
import InstagramIcon from "components/icons/InstagramIcon";
import LinkedinIcon from "components/icons/LinkedinIcon";
import FacebookIcon from "components/icons/FacebookIcon";
import AppleIcon from "components/icons/AppleIcon";
import PlayStoreIcon from "components/icons/PlayStoreIcon";
import { routeNameExist } from "constants/route";

export default function Footer() {
  const pathname = useLocation().pathname;

  if (!routeNameExist(pathname)) return <></>;
  return (
    <footer className="w-full bg-dark-blue pt-14 pb-8">
      <div className="w-full mx-auto max-w-1246 px-6">
        <div className="w-full flex flex-col sm:flex-row gap-y-6 justify-between items-center">
          <div className="flex items-center gap-x-4">
            <img src={logo} alt="logo" height={20} className="h-6" />
            <p className="uppercase text-base font-bold tracking-widest">
              Melabooks
            </p>
          </div>
          <div className="flex items-center gap-x-3">
            <Link
              to={"#"}
              className="w-9 h-9 bg-white-o-50 rounded-full flex items-center justify-center"
            >
              <InstagramIcon />
            </Link>
            <Link
              to={"#"}
              className="w-9 h-9 bg-white-o-50 rounded-full flex items-center justify-center"
            >
              <FacebookIcon />
            </Link>
            <Link
              to={"#"}
              className="w-9 h-9 bg-white-o-50 rounded-full flex items-center justify-center"
            >
              <LinkedinIcon />
            </Link>
          </div>
        </div>
        <div className="w-full flex flex-col sm:flex-row gap-y-14 mt-24 items-center sm:items-start justify-between">
          <div style={{ width: "280px" }}>
            <h6 className="font-bold text-lg uppercase">Menu</h6>
            <div className="w-full mt-9 border-b border-b-white" />
            <div className="w-full mt-9 flex justify-between">
              <div className="flex flex-col gap-y-4">
                <Link to={"#"} className="uppercase text-sm font-medium">
                  Home
                </Link>
                <Link to={"#"} className="uppercase text-sm font-medium">
                  About
                </Link>
                <Link to={"#"} className="uppercase text-sm font-medium">
                  Pricing
                </Link>
              </div>
              <div className="flex flex-col gap-y-4">
                <Link to={"#"} className="uppercase text-sm font-medium">
                  Blog
                </Link>
                <Link to={"#"} className="uppercase text-sm font-medium">
                  contact us
                </Link>
              </div>
            </div>
          </div>
          <div className="rounded-40 bg-white-o-10 px-11 py-11 w-342 sm:w-509">
            <h6 className="uppercase font-bold text-lg tracking-wider">
              Download our Application
            </h6>
            <p className="text-lg font-normal mt-3">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris
              sed nulla integer{" "}
            </p>
            <div className="w-full mt-6 flex flex-col sm:flex-row gap-4">
              <Link
                to={"#"}
                className="bg-blue rounded-80 px-7 py-5 gap-x-2 flex items-center w-full justify-center"
              >
                <AppleIcon />
                <span className="text-base font-bold tracking-widest uppercase">
                  App Store
                </span>
              </Link>
              <Link
                to={"#"}
                className="bg-blue rounded-80 px-7 py-5 gap-x-2 flex items-center w-full justify-center"
              >
                <PlayStoreIcon />
                <span className="text-base font-bold tracking-widest uppercase">
                  PLAY STORE
                </span>
              </Link>
            </div>
          </div>
        </div>
        <div className="w-full mt-32 border-b border-b-white-o-30"></div>
        <p className="mt-8 text-center sm:text-start text-base font-normal">
          All rights reserved{" "}
        </p>
      </div>
    </footer>
  );
}
