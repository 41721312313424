import PageLoader from "components/Suspense/PageLoader";
import SuspenseLoader from "components/Suspense/SuspenseLoader";
import { RouteName } from "constants/route";
import BaseLayout from "layouts/BaseLayout";
import { lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

const HomePage = PageLoader(lazy(() => import("pages/Home")));
const AboutPage = PageLoader(lazy(() => import("pages/About/Index")));
const ContactPage = PageLoader(lazy(() => import("pages/Contact/Index")));
const PricingPage = PageLoader(lazy(() => import("pages/Pricing")));
const BlogPage = PageLoader(lazy(() => import("pages/Blog")));
const BlogDetailPage = PageLoader(lazy(() => import("pages/BlogDetail")));
const Status404Page = PageLoader(lazy(() => import("pages/Status404")));

function App() {
  return (
    <BrowserRouter>
      <SuspenseLoader>
        <Routes>
          <Route path="" Component={BaseLayout}>
            <Route path={RouteName.Home} Component={HomePage}></Route>
            <Route path={RouteName.About} Component={AboutPage}></Route>
            <Route path={RouteName.Contact} Component={ContactPage}></Route>
            <Route path={RouteName.Pricing} Component={PricingPage}></Route>
            <Route path={RouteName.Blog} Component={BlogPage}></Route>
            <Route
              path={RouteName.BlogDetail}
              Component={BlogDetailPage}
            ></Route>
            <Route path={RouteName.Status404} Component={Status404Page}></Route>
          </Route>
        </Routes>
      </SuspenseLoader>
    </BrowserRouter>
  );
}

export default App;
