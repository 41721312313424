export default function LinkedinIcon() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.47427 20V6.62192H0.313199V20H4.47427ZM2.37136 4.83221C3.71365 4.83221 4.78747 3.71365 4.78747 2.37136C4.78747 1.07383 3.71365 0 2.37136 0C1.07383 0 0 1.07383 0 2.37136C0 3.71365 1.07383 4.83221 2.37136 4.83221ZM20.0447 20V12.6622C20.0447 9.08277 19.2394 6.30872 15.0336 6.30872C13.0201 6.30872 11.6779 7.42729 11.0962 8.45638H11.0515V6.62192H7.06935V20H11.2304V13.3781C11.2304 11.6331 11.5436 9.97763 13.6913 9.97763C15.8389 9.97763 15.8837 11.9463 15.8837 13.5123V20H20.0447Z"
        fill="white"
      />
    </svg>
  );
}
